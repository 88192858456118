import axios from '../../utils/axios';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';

export function fetchUser(userId) {
  return (dispatch, store) => {
    if (!userId) return;
    const { user } = store();

    // Get from cache
    if (user.id === userId) return;

    // Set loading state
    dispatch({ type: 'FETCH_USER' });

    axios.post(`https://api.m2k.io/api/rest?userId=${userId}`, { m2k: { ...window.powerpeers.mocks.user } }, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('ppci_access_token')}`,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        if (!data.success) {
          const { message = '' } = data;
          const [_, errorMessage] = message.split(':');
          throw new Error(errorMessage);
        }
        dispatch({
          type: FETCH_USER_SUCCESS,
          user: data.content,
        });
      }).catch((error) => {
        dispatch({
          type: FETCH_USER_FAILED,
          message: error.message,
        });
      });
  };
}
