/* Responsive utils */
export const xlMinWidth = '1200'; // Extra large devices (large desktops)
export const xlMaxWidth = '1199'; // Extra large devices (large desktops)
export const mdMinWidth = '992'; // Large devices (desktops)
export const mdMaxWidth = '991'; // Large devices (desktops)
export const smMinWidth = '768'; // Medium devices (tablets)
export const smMaxWidth = '767'; // Medium devices (tablets)
export const xsMinWidth = '544'; // Small devices (landscape phones)
export const xsMaxWidth = '543'; // Small devices (landscape phones)

export default {
  xlMinWidth,
  xlMaxWidth,
  mdMinWidth,
  mdMaxWidth,
  smMinWidth,
  smMaxWidth,
  xsMinWidth,
  xsMaxWidth,
};
