import styled from 'styled-components';

/* Constants */
import { primary, gray, gray10 } from '../../constants/colors';
import { space } from '../../constants/sizes';
import { xsMinWidth } from '../../constants/breakpoints';

/* Styles */
import { icomoon } from '../../styles/icomoon';

export default styled.div`
  display: none;
  align-items: center;
  height: calc(100% + 1px);
  min-width: 220px;
  max-width: 400px;
  padding: 0 ${space}px;
  position: relative;
  background-color: white;
  border-right: 1px solid ${gray10};
  border-left: 1px solid ${gray10};
  border-bottom: 1px solid ${gray10};
  font-weight: 300;
  line-height: 20px;
  cursor: pointer;

  @media only screen and (min-width: ${xsMinWidth}px) {
    display: flex;
  }

  .avatar {
    margin-right: ${space / 2}px;

    img {
      width: 40px;
      height: 40px;
    }
  }


  .current {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .address {
    color: var(--primary,${primary});
    font-size: 14px;
    cursor: pointer;
  }

  .arrow {
    ${icomoon}
    width: 12px;
    height: 12px;
    font-size: 12px;
    cursor: pointer;
    margin: 0 0 0 ${space}px;

    &:before {
      content: "\\e904";
      position: absolute;
      transition: transform 300ms ease-out;
    }

    &.open:before {
      transform: rotate(180deg);
    }
  }

  .dropdown {
    position: absolute;
    bottom: 0px;
    left: -1px;
    z-index: -1;
    width: calc(100% + 2px);
    border-radius: 0 0 2px 2px;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    transition: transform 0.2s ease-out;
    border: 1px solid ${gray10};
    max-height: calc(100vh - 80px);

    &.open {
      transform: translateY(100%);
    }
  }

  .link {
    width: 100%;
    display: flex;
    align-items: center;
    color: black;
    font-weight: 700;
    padding: 16px;
    background: white;
    border-bottom: 1px solid ${gray10};
    position: relative;
    text-align: left;
    transition: background-color 0.2s ease-out;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: ${gray10};
    }

    &.account {
      width: 100%;
      font-weight: 700;
      margin-bottom: 0;

      .description {
        overflow: hidden;
      }

      .type {
        line-height: 20px;
      }

      .id {
        display: block;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover .id {
        color: var(--primary,${primary});
      }
    }
  }

  .address-sub {
    color: ${gray};
    font-weight: 400;
  }
`;
