const hash = 'lv5pfx';

export const icomoon = `
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default `
  @font-face {
    font-family: 'icomoon';
    src:  url(${`${window.powerpeers.ASSETS_ROOT}/fonts/icomoon.eot?${hash}`});
    src:  url(${`${window.powerpeers.ASSETS_ROOT}/fonts/icomoon.eot?${hash}#iefix`}) format('embedded-opentype'),
      url(${`${window.powerpeers.ASSETS_ROOT}/fonts/icomoon.ttf?${hash}`}) format('truetype'),
      url(${`${window.powerpeers.ASSETS_ROOT}/fonts/icomoon.woff?${hash}`}) format('woff'),
      url(${`${window.powerpeers.ASSETS_ROOT}/fonts/icomoon.svg?${hash}#icomoon`}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^='icmn-'], [class*=' icmn-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    ${icomoon}
  }

  .icmn-redo:before {
    content: "\\e905";
  }
  .icmn-chevron-down:before {
    content: "\\e904";
  }
  .icmn-balance:before {
    content: "\\e900";
  }
  .icmn-bookings:before {
    content: "\\e901";
  }
  .icmn-favorites:before {
    content: "\\e902";
  }
  .icmn-overview:before {
    content: "\\e903";
  }
`;
