import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* Style */
import StyledComponent from './style';

const MainMenu = ({ open }) => {
  const { t } = useTranslation();
  const items = [
    {
      label: t('menu.dashboard'),
      icon: 'overview',
      link: '/',
    },
    {
      label: t('menu.usageSupply'),
      icon: 'bookings',
      link: 'usage-supply',
    },
    {
      label: t('menu.sources'),
      icon: 'favorites',
      link: 'sources',
    },
  ];

  const renderMenu = () => {
    const html = items.map((item) => {
      return (
        <li key={item.label}>
          {
            item.link
            && (
              <NavLink
                to={item.link}
                exact={item.link === '/'}
                className="link"
              >
                <i className={`icmn-${item.icon}`} />
                {item.label}
              </NavLink>
            )
          }
          {
            item.href
            && (
              <a
                href={item.href}
                className="link"
              >
                <i className={`icmn-${item.icon}`} />
                {item.label}
              </a>
            )
          }
        </li>
      );
    });

    return (
      <ul>{html}</ul>
    );
  };

  return (
    <StyledComponent className={open && 'open'}>
      {renderMenu()}
    </StyledComponent>
  );
};

MainMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    open: state.app.openMainMenu,
  }),
)(MainMenu);
