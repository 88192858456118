import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

/* Style */
import StyledComponent from './style';

const Radio = ({
  name,
  value,
  holderClassName,
  className,
  checked,
  onChange,
  error,
  children,
}) => {
  const [check, setChecked] = useState(checked);
  const textInput = React.createRef();

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  /**
   * Set checked state on value change
   * @param {Event} e
   * $return {void}
   */
  const setCheckedState = (e) => {
    setChecked(e.currentTarget.checked);

    if (onChange) {
      // trigger on change callback
      onChange(name, e.currentTarget.value);
    }
  };

  const holderClassNames = classNames(holderClassName, {
    checked: check,
    error,
  });

  const spanClasses = classNames('radio', className, {
    checked: check,
    error,
  });

  return (
    <StyledComponent htmlFor={`${name}-${value}`} className={holderClassNames}>
      <span className={spanClasses} />
      <input
        ref={textInput}
        type="radio"
        className="field"
        checked={check}
        id={`${name}-${value}`}
        name={name}
        value={value}
        onChange={setCheckedState}
      />
      {children}
    </StyledComponent>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  holderClassName: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.any,
  children: PropTypes.node,
};

Radio.defaultProps = {
  checked: false,
  holderClassName: null,
  className: null,
  onChange: null,
  error: null,
  children: null,
};

export default Radio;
