import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
} from '../actions/user';

const INITIAL_STATE = {
  fetching: true,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...action.user,
        fetching: false,
        error: null,
      };
    case FETCH_USER_FAILED:
      return {
        fetching: false,
        error: {
          title: 'Sorry Couldn\'t load user profile',
          message: action.message,
        },
      };
    default:
      return state;
  }
}
