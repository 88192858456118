import {
  TOGGLE_PROPS,
  SWITCH_DEVICE,
} from '../actions/app';

const INITIAL_STATE = {
  openMainMenu: localStorage.getItem('openMainMenu') === 'true',
  selectedDeviceId: localStorage.getItem('selectedDeviceId') || null,
  googleMapsApiKey: 'AIzaSyBN1Qz3Agvd3RWMMZlWkOTe5BlJSS_YGMI',
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_PROPS:
      return {
        ...state,
        [action.prop]: action.bool,
      };
    case SWITCH_DEVICE:
      return {
        ...state,
        selectedDeviceId: action.id,
      };
    default:
      return state;
  }
}
