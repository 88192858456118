/* Colors */
export const white = '#ffffff';
export const black = '#000000';

export const primary = '#1e99e1';
export const primaryLight = '#32b4e9';
export const primaryDark = '#037cc2';

export const gray = '#282d32';
export const gray10 = '#eaeaeb';
export const gray15 = '#dfdfe0';
export const gray30 = '#bfc0c2';
export const gray45 = '#9fa1a3';
export const gray60 = '#7e8184';
export const gray75 = '#5e6265';

export const sunColor = '#f5a72f';
export const windColor = '#17a087';
export const waterColor = '#1c8fd3';
