import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useDeviceInfo from '../../utils/device-hook';

/* Components */
import Grid from '../../components/grid';
import Widget from '../../components/widget';

/* Widgets */
import '@ppci-mock/allocation-graph';
import '@ppci-mock/top-sources';
import '@ppci-mock/your-sources';

export const Usage = ({ selectedDeviceId, devices }) => {
  const { localization = {} } = window.powerpeers;
  const { resources = {} } = localization;
  const { common = {} } = resources;
  const { backupItem = {} } = common;

  const backUp = {
    name: backupItem.name,
    city: backupItem.city,
    avatar: window.powerpeers.BACKUP_LOGO,
  };

  const { t } = useTranslation();
  const { hasEstimatedSupply, hasEstimatedUsage } = useDeviceInfo(selectedDeviceId, devices);
  return (
    <Grid>
      {hasEstimatedUsage && (
        <Widget title={t('components.usedEnergy.title')}>
          <allocation-graph
            deviceId={selectedDeviceId}
            direction="usage"
          />
        </Widget>
      )}
      {hasEstimatedSupply && (
        <Widget title={t('components.sharedEnergy.title')}>
          <allocation-graph
            deviceId={selectedDeviceId}
            direction="delivery"
          />
        </Widget>
      )}
      <Grid columns={2}>
        {hasEstimatedUsage && (
          <Widget title={t('components.topXConsumers.title')}>
            <top-sources
              deviceId={selectedDeviceId}
              limit={3}
              direction="USAGE"
              energyType="ELECTRICITY"
              backupName={backUp.name}
              backupCity={backUp.city}
              backupAvatar={backUp.avatar}
            />
          </Widget>
        )}
        {hasEstimatedSupply && (
          <Widget title={t('components.topXSuppliers.title')}>
            <top-sources
              deviceId={selectedDeviceId}
              direction="DELIVERY"
              limit={3}
              energyType="ELECTRICITY"
              backupName={backUp.name}
              backupCity={backUp.city}
              backupAvatar={backUp.avatar}
            />
          </Widget>
        )}
        {hasEstimatedUsage && (
          <Widget title={t('components.yourConsumers.title')}>
            <your-sources
              deviceId={selectedDeviceId}
              direction="USAGE"
              energyType="ELECTRICITY"
              sortBy="AMOUNT"
              backupName={backUp.name}
              backupCity={backUp.city}
              backupAvatar={backUp.avatar}
            />
          </Widget>
        )}
        {hasEstimatedSupply && (
          <Widget title={t('components.yourSuppliers.title')}>
            <your-sources
              deviceId={selectedDeviceId}
              direction="DELIVERY"
              energyType="ELECTRICITY"
              sortBy="AMOUNT"
              backupName={backUp.name}
              backupCity={backUp.city}
              backupAvatar={backUp.avatar}
            />
          </Widget>
        )}
      </Grid>
    </Grid>
  );
};

Usage.propTypes = {
  selectedDeviceId: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect((state) => ({
  selectedDeviceId: state.app.selectedDeviceId,
  devices: state.devices.all,
}))(Usage);
