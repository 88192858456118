import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/sizes';
import { gray, gray15 } from '../../constants/colors';

export default styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 ${gray15};
  background-color: #ffffff;
  overflow: hidden;

  .title {
    font-family: Raleway;
    font-size: 1.25rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4rem;
    letter-spacing: normal;
    color: ${gray};
    padding: ${space * 1.25}px ${space}px;
  }
`;
