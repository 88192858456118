import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Grid = ({ children, order, columns }) => {
  let gridColumns = 'repeat(auto-fit, minmax(1px, 1fr))';

  if (columns) {
    gridColumns = typeof columns === 'string' ? `${columns}` : gridColumns;
    gridColumns = typeof columns === 'number' ? `repeat(${columns}, 1fr)` : gridColumns;
    gridColumns = typeof columns === 'object' ? columns : gridColumns;
  }

  return (
    <StyledComponent
      order={order}
      gridColumns={gridColumns}
    >
      {children}
    </StyledComponent>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  order: PropTypes.number,
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // 1fr 2fr 1fr
    PropTypes.shape({}),
  ]),
};

Grid.defaultProps = {
  order: 0,
  columns: 1,
};

export default Grid;
