import {
  FETCH_DEVICES,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILED,
} from '../actions/devices';

const INITIAL_STATE = {
  fetching: true,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DEVICES:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_DEVICES_SUCCESS:
      return {
        all: action.devices,
        fetching: false,
        error: null,
      };
    case FETCH_DEVICES_FAILED:
      return {
        fetching: false,
        error: {
          title: 'Sorry  Couldn\'t load devices',
          message: action.message,
        },
      };
    default:
      return state;
  }
}
