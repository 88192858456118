import axios from '../../utils/axios';
import { switchSelectedDevice } from './app';

export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAILED = 'FETCH_DEVICES_FAILED';


export function fetchDevices(userId) {
  return (dispatch, store) => {
    if (!userId) return;
    const { app, devices } = store();

    // Get from cache
    if (devices.all && devices.all.length > 0) return;

    // Set loading state
    dispatch({ type: 'FETCH_DEVICES' });

    axios.post(`https://api.m2k.io/api/rest?userId=${userId}`, { m2k: { ...window.powerpeers.mocks.devices } }, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('ppci_access_token')}`,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        if (!data.success) {
          const { message = '' } = data;
          const [_, errorMessage] = message.split(':');
          throw new Error(errorMessage);
        }
        if (!app.selectedAccountid) dispatch(switchSelectedDevice(data.content[0].id));

        dispatch({
          type: FETCH_DEVICES_SUCCESS,
          devices: data.content,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_DEVICES_FAILED,
          message: error.message,
        });
      });
  };
}
