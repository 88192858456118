export const TOGGLE_PROPS = 'TOGGLE_PROPS';
export const SWITCH_DEVICE = 'SWITCH_DEVICE';

export function toggleProps(prop, bool) {
  return (dispatch, store) => {
    const { app } = store();
    const newBool = typeof bool !== 'undefined' ? bool : !app[prop];
    const cookieItem = [
      'openMainMenu',
    ];

    if (cookieItem.indexOf(prop) >= 0) {
      localStorage.setItem(prop, newBool);
    }

    dispatch({
      type: TOGGLE_PROPS,
      bool: newBool,
      prop,
    });
  };
}

export function switchSelectedDevice(id) {
  return (dispatch, store) => {
    const { app } = store();

    if (app.selectedDeviceId !== id) {
      localStorage.setItem('selectedDeviceId', id);

      dispatch({
        type: SWITCH_DEVICE,
        id,
      });
    }
  };
}
