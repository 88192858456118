import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Components */
import MainMenu from '../main-menu';

export default connect(
	state => ({
		open: state.app.openMainMenu,
	}),
)(class PageWrapper extends React.Component {
	static propTypes = {
    open: PropTypes.bool.isRequired,
	};

	render() {
		const { open } = this.props;

		return (
			<StyledComponent className={open && 'open'}>
        <div className="side">
          <div className="wrap">
            <div className="scroll">
              <MainMenu />
            </div>
          </div>
        </div>
        <div className="page">
          <div className="container">
            {this.props.children}
          </div>
        </div>
			</StyledComponent>
		);
	}
});
