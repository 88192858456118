import styled from 'styled-components';

/* Constants */
import { gray10 } from '../../constants/colors';

export default styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${gray10};
`;
