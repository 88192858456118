import React, { useEffect } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Utils */
import useTranslatorInitializer from './utils/translator';

/* Components */
import Header from './components/header';
import PageWrapper from './components/page-wrapper';
import GlobalError from './components/global-error';

/* Pages */
import Dashboard from './pages/dashboard';
import Usage from './pages/usage';
import Sources from './pages/sources';

/* Redux */
import { fetchUser } from './redux/actions/user';
import { fetchDevices } from './redux/actions/devices';
import Loader from './components/loader';

const App = ({
  dispatch, userFetching, userError,
  devicesFetching, devicesError,
}) => {
  const { loadingTranslations, translationsError } = useTranslatorInitializer();

  // Load devices
  useEffect(() => {
    dispatch(fetchUser(window.powerpeers.DEFAULT_USER_ID));
    dispatch(fetchDevices(window.powerpeers.DEFAULT_USER_ID));
  }, [window.powerpeers.DEFAULT_USER_ID]);

  if (loadingTranslations) {
    return (
      <Loader />
    );
  }

  if (userFetching || devicesFetching) {
    return (
      <Loader />
    );
  }

  if (devicesError || userError || translationsError) {
    const unknownError = { title: 'Something went wrong!', message: 'Unknown error' };
    const { title, message } = devicesError || userError || translationsError || unknownError;
    return (
      <GlobalError
        title={title}
        message={message}
        retryTitle="Try Again"
      >
        <span>Or</span>
        <NavLink to="/logout" className="logoutButton">
          Logout
        </NavLink>
      </GlobalError>
    );
  }

  return (
    <>
      <Header />
      <Header fake />
      <PageWrapper>
        <Switch>
          <Route path="/" component={Dashboard} exact />
          <Route path="/usage-supply" component={Usage} />
          <Route path="/sources" component={Sources} />
        </Switch>
      </PageWrapper>
    </>
  );
};

App.defaultProps = {
  userError: null,
  devicesError: null,
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userFetching: PropTypes.bool.isRequired,
  devicesFetching: PropTypes.bool.isRequired,
  userError: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  devicesError: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default connect((state) => ({
  userFetching: state.user.fetching,
  userError: state.user.error,
  devicesFetching: state.devices.fetching,
  devicesError: state.devices.error,
}))(App);
