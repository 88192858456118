import styled from 'styled-components';

/* Constants */
import { space, spaceSmall } from '../../constants/sizes';
import { primary, gray } from '../../constants/colors';

const radioSize = 20;
const iconSize = radioSize / 2;

export default styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  line-height: ${radioSize / 1.5}px;
  cursor: pointer;
  overflow: hidden;
  margin-right: ${space}px;
  margin-bottom: ${spaceSmall}px;

  &:hover {
    .radio {
      &:before {
        opacity: 0.2;
        transform: scale(1);
      }
    }
  }

  &.checked:hover {
    .radio {
      &:before {
        opacity: 1;
      }
    }
  }

  .radio {
    position: relative;
    box-sizing: border-box;
    text-align: center;
    display: block;
    width: ${radioSize}px;
    min-width: ${radioSize}px;
    height: ${radioSize}px;
    margin-right: ${spaceSmall}px;
    border: 1px solid ${gray};
    border-radius: 50%;
    transition: all 0.2s ease-out;

    &:before {
      opacity: 0;
      content: '';
      width: ${iconSize}px;
      height: ${iconSize}px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -${iconSize / 2}px;
      margin-top: -${iconSize / 2}px;
      background: var(--primary,${primary});
      border-radius: 50%;
      transform: scale(0.2);
      transition: all 0.2s ease-out;
    }

    &.checked {
      &:before {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.error {
      color: $errorColor;

      &:before {
        border-color: $errorColor;
      }
    }
  }

  .field {
    position: fixed;
    top: -500px;
    left: -500px;
  }
`;
