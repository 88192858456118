import React from 'react';
import ReactDOM from 'react-dom';

/* Components */
import App from './App';

ReactDOM.render(
  React.createElement(App),
  document.getElementById('root'),
);
