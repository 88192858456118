import styled from 'styled-components';

/* Constants */
import { space, spaceLarge } from '../../constants/sizes';
import { gray10 } from '../../constants/colors';
import { mdMinWidth } from '../../constants/breakpoints';
import { sidebarWidth, sidebarWidthOpen } from '../../constants/sizes';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.section`
  display: flex;
  flex-direction: row;
  position: relative;

  &.open {
    .side {
      @media (min-width: ${mdMinWidth}px) {
        width: ${sidebarWidthOpen}px;
      }

      .wrap {
        width: 95%;
        max-width: ${sidebarWidthOpen}px;

        @media (min-width: ${mdMinWidth}px) {
          width: ${sidebarWidthOpen}px;
        }
      }
    }
  }

  .side {
    width: 0;
    flex-shrink: 0;
    overflow: hidden;
    transition: width 0.3s;
    position: relative;

    @media (min-width: ${mdMinWidth}px) {
      width: ${sidebarWidth}px;
    }

    .wrap {
      width: 0;
      max-width: ${sidebarWidthOpen}px;
      height: 100%;
      overflow: hidden;
      position: fixed;
      z-index: ${zindex('sidebar')};
      background-color: white;
      border-right: 1px solid ${gray10};
      transition: width 0.3s ease-out;

      @media (min-width: ${mdMinWidth}px) {
        width: ${sidebarWidth}px;
      }
    }

    .scroll {
      max-width: 320px;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .page {
    width: 100%;
    overflow: hidden;
    position: relative;

    .container {
      width: 1440px;
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden;
      padding: ${spaceLarge}px ${space}px ${spaceLarge}px ${space}px;
    }
  }
`;
