import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Widget = ({ title, children, className }) => {
  return (
    <StyledComponent className={className}>
      {title && (
        <h3 className="title">{title}</h3>
      )}
      {children}
    </StyledComponent>
  );
};

Widget.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Widget.defaultProps = {
  title: null,
  className: '',
};

export default Widget;
