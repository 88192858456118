import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

export const GlobalError = ({
  title, message, retryTitle, retryAction,
  children,
}) => (
  <StyledComponent>
    <div className="globalError">
      <h2>{title}</h2>
      <p>{message}</p>
      {retryTitle && retryAction && (
        <button
          className="retry"
          type="button"
          onClick={retryAction}
        >
          {retryTitle}
        </button>
      )}
      {children}
    </div>
  </StyledComponent>
);

GlobalError.defaultProps = {
  retryTitle: null,
  retryAction: null,
  children: null,
};

GlobalError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  retryTitle: PropTypes.string,
  retryAction: PropTypes.func,
  children: PropTypes.node,
};

export default GlobalError;
