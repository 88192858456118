import styled from 'styled-components';

export default styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  custom-loader {
    --stroke-color: var(--primary);
    --size: 48px;
  }
`;
