import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Avatar = ({ image }) => (
  <StyledComponent>
    {image && <img src={image} alt="profile" />}
  </StyledComponent>
);

Avatar.propTypes = {
  image: PropTypes.string,
};

Avatar.defaultProps = {
  image: '',
};

export default Avatar;
