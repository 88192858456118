import styled from 'styled-components';

/* Constants */
import { space, spaceSmall } from '../../constants/sizes';
import { smMinWidth } from '../../constants/breakpoints';
import {
  gray, gray10, gray75, gray60, gray15,
} from '../../constants/colors';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.header`
  height: 70px;
  width: 100vw;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zindex('header')};
  background-color: white;
  border-bottom: 1px solid ${gray10};
  transition: top 0.1s;

  &.fake {
    position: relative;
    visibility: hidden;
    z-index: -1;
  }

  .logo {
    margin-left: 30px;
    display: flex;
    align-items: center;

    img {
      height: 40px;
    }
  }

  .menu-toggle {
    width: 80px;
    position: relative;
    cursor: pointer;

    @media (min-width: ${smMinWidth}px) {
      border-right: 1px solid ${gray10};
    }

    &:hover > *,
    &:hover > *:before,
    &:hover > *:after {
      background-color: ${gray75};
    }
  }

  .hamburger {
    width: 24px;
    height: 3px;
    margin: -2px 0 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 2px;
    background-color: ${gray};
    transition: background 0.2s ease-out;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      width: 24px;
      height: 3px;
      margin: 0 0 0 -12px;
      border-radius: 2px;
      background-color: ${gray};
      transition: background 0.2s ease-out, transform 0.2s ease-out;
    }

    &:after {
      top: 8px;
    }

    &.open {
      background-color: transparent !important;

      &:before {
        transform: translateY(8px) rotate(45deg);
      }

      &:after {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  & .content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  & .content--right {
    height: 100%;
    display: flex;
  }

  .userProfile {
    display: flex;
    flex-direction: row;
    border-left: 1px solid ${gray15};
    padding: 0 ${space}px;
    font-family: 'Open Sans', sans-serif;
    height: 100%;

    & .column--left,
    & .column--right {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    & .column--left {
      margin-right: ${spaceSmall}px;
    }

    &__image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    & .firstName {
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 600;
      color: ${gray};
    }

    & .lastName {
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: ${gray60};
    }

  }
`;
