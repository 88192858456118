import { useEffect, useState } from 'react';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const readConfig = () => {
  return window && window.powerpeers
    ? window.powerpeers.localization
    : null;
};

const translationError = {
  title: 'Something went',
  message: 'While loading translations',
};

export default () => {
  const [translatorLoading, setLoading] = useState(true);
  const [translatorError, setError] = useState(null);

  const setupTranslator = () => {
    const config = readConfig();
    if (!config) return;
    const { language, resources } = config;
    const { demoPage } = resources;

    const translations = {
      [language]: {
        translation: {
          ...demoPage,
        },
      },
    };

    const newInstance = i18n.createInstance();
    newInstance
      // passes i18n down to react-i18next
      .use(initReactI18next)
      .init({
        resources: translations,
        ns: ['translation'],
        lng: language,
        interpolation: {
          // not needed for react as it escapes by default
          escapeValue: false,
        },
      }).then(() => {
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        setError(translationError);
      });
  };

  // TODO: this could be improved by using a Proxy on the window
  // or an custom event.
  const detectLocalizationChanges = (onComplete) => {
    const maxAttempts = 10;
    let attempt = 0;
    const detectWindowChangeId = setInterval(() => {
      const hasLocalization = readConfig() != null;
      attempt += 1;
      if (attempt >= maxAttempts) {
        clearInterval(detectWindowChangeId);
        setError(translationError);
        return;
      }
      if (hasLocalization) {
        clearInterval(detectWindowChangeId);
        onComplete();
      }
    }, 300);
  };

  useEffect(() => {
    detectLocalizationChanges(() => {
      setupTranslator();
    });
  }, [
    detectLocalizationChanges, setLoading,
    setupTranslator, readConfig, setError,
  ]);

  return { translatorLoading, translatorError };
};
