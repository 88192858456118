import styled from 'styled-components';

/* Constants */
import { space, spaceSmall, spaceLarge } from '../../constants/sizes';

export default styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .globalError {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    flex-direction: column;
    padding: ${space};
  }

  h2 {
    padding: 0;
    margin: 0 0 0 0;
    font-family: Raleway;
    color: var(--gray75);
    font-size: 1.125rem;
    line-height: 1.44rem;
    text-align: center;
  }

  p {
    padding: 0;
    margin: 0 0 ${spaceSmall}px 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--gray45);
    text-align: center;
  }

  button.retry {
    margin: ${spaceSmall}px ${space}px ${space}px ${space}px;
    color: var(--primary);
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    text-transform: uppercase;
    background: white;
    border: 1px solid var(--primary);
    padding: ${spaceSmall}px ${spaceLarge}px;
    border-radius: 3px;
    cursor: pointer;
  }

  button.retry:hover {
    background: var(--gray30);
  }

  button.retry:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\\e905';
    margin-right: ${spaceSmall}px;
    font-size: 0.75rem;
  }

`;
