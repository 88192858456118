import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* Components */
import Routes from './Routes';

/* Style */
import GlobalStyle from './styles/index';

/* Redux */
import reducers from './redux/index';

/* Pages */
import LogoutPage from './pages/LogoutPage';


const App = () => {
  const preloadedState = window.PRELOADED_STATE || {};
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
  const store = createStore(
    reducers,
    {
      ...preloadedState,
    },
    composeEnhancers(applyMiddleware(thunk)),
  );

  return (
    <Provider store={store}>
      <GlobalStyle />
      <Router basename="/demo">
        <Switch>
          <Route path="/logout" component={LogoutPage} />
          <Route path="/" component={Routes} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
