import styled, { css } from 'styled-components';

/* Constants */
import { space } from '../../constants/sizes';
import breakpoints, { xsMinWidth } from '../../constants/breakpoints';

export default styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${space}px;
  row-gap: ${space}px;
  order: ${(props) => props.order};

  /* Set columns in string format */
  ${(props) =>
    typeof props.gridColumns === 'string'
    && css`@media (min-width: ${xsMinWidth}px) {
      order: unset;
      grid-template-columns: ${(props) => props.gridColumns};
    }`
  }

  /* Loop object with break points */
  ${(props) =>
    typeof props.gridColumns === 'object'
    && Object.keys(props.gridColumns).map((key) => {
      return css`@media (min-width: ${breakpoints[key]}px) {
        order: unset;
        grid-template-columns: ${props.gridColumns[key]};
      }`
    })
  }

  & & {
    grid-template-rows: repeat(auto-fit, minmax(min-content, 1px));
  }
`;
