import { combineReducers } from 'redux';
import app from './reducers/app';
import devices from './reducers/devices';
import user from './reducers/user';

const reducers = combineReducers({
  app,
  devices,
  user,
});

export default reducers;
