import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useDeviceInfo from '../../utils/device-hook';

/* Components */
import Grid from '../../components/grid';
import Widget from '../../components/widget';

import '@ppci-mock/yearly-usage';
import '@ppci-mock/generated-together';
import '@ppci-mock/usage-origin';
import '@ppci-mock/top-sources';


const Dashboard = ({ selectedDeviceId, devices }) => {
  const { localization = {} } = window.powerpeers;
  const { resources = {} } = localization;
  const { common = {} } = resources;
  const { backupItem = {} } = common;

  const backUp = {
    name: backupItem.name,
    city: backupItem.city,
    avatar: window.powerpeers.BACKUP_LOGO,
  };

  const { t } = useTranslation();
  const { hasEstimatedSupply, hasEstimatedUsage } = useDeviceInfo(selectedDeviceId, devices);
  return (
    <Grid columns={2}>
      {hasEstimatedUsage && (
        <Widget title={t('components.yearlyUsage.title')}>
          <yearly-usage
            direction="USAGE"
            deviceId={selectedDeviceId}
          />
        </Widget>
      )}
      {hasEstimatedSupply && (
        <Widget title={t('components.yearlyDelivery.title')}>
          <yearly-usage
            direction="DELIVERY"
            deviceId={selectedDeviceId}
          />
        </Widget>
      )}
      <Widget title={t('components.generatedTogether.title')}>
        <generated-together
          energyType="electricity"
          direction="usage"
          startDate="2019-01-01"
          endDate="2019-12-31"
          isHorizontal
        />
      </Widget>
      <Widget title={t('components.energyOrigin.title')}>
        <usage-origin
          deviceId={selectedDeviceId}
        />
      </Widget>
      {hasEstimatedUsage && (
        <Widget title={t('components.topConsumers.title')}>
          <top-sources
            deviceId={selectedDeviceId}
            direction="USAGE"
            energyType="ELECTRICITY"
            backupName={backUp.name}
            backupCity={backUp.city}
            backupAvatar={backUp.avatar}
          />
        </Widget>
      )}
    </Grid>
  );
};

Dashboard.propTypes = {
  selectedDeviceId: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect((state) => ({
  selectedDeviceId: state.app.selectedDeviceId,
  devices: state.devices.all,
}))(Dashboard);
