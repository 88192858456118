import { createGlobalStyle } from 'styled-components';

/* Styles */
import core from './core';
import icomoon from './icomoon';

export default createGlobalStyle`
  ${core}
  ${icomoon}
`;
