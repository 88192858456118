import React from 'react';
/* Style */
import StyledComponent from './style';

/* Components */
import '@ppci-mock/custom-loader';

export const Loader = () => (
  <StyledComponent>
    <custom-loader />
  </StyledComponent>
);

export default Loader;
