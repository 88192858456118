import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/* Components */
import Grid from '../../components/grid';
import Widget from '../../components/widget';

/* Widgets */
import '@ppci-mock/source-mix';
import '@ppci-mock/source-overview';
import '@ppci-mock/source-map';
import '@ppci-mock/generated-together';
import '@ppci-mock/choose-for-me';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const jumpToSourceOverview = (ref) => {
  scrollToRef(ref);
};

const Sources = ({ selectedDeviceId, googleMapsApiKey }) => {
  const { t } = useTranslation();
  const sourceOverviewRef = useRef(null);
  const socialSourceMixRef = useRef(null);
  const localSourceMixRef = useRef(null);

  useEffect(() => {
    if (!socialSourceMixRef) return;
    socialSourceMixRef.current.addEventListener('add-click', () => {
      jumpToSourceOverview(sourceOverviewRef);
    });
  }, [socialSourceMixRef, sourceOverviewRef]);

  useEffect(() => {
    if (!localSourceMixRef) return;
    localSourceMixRef.current.addEventListener('add-click', () => {
      jumpToSourceOverview(sourceOverviewRef);
    });
  }, [localSourceMixRef, sourceOverviewRef]);

  return (
    <Grid>
      <Grid columns={3}>
        <Widget title={t('components.socialSources.title')}>
          <source-mix
            ref={socialSourceMixRef}
            usage="USAGE"
            categoryIds="[1]"
            deviceId={selectedDeviceId}
          />
          <choose-for-me
            deviceId={selectedDeviceId}
            title={t('components.localSources.chooseForMeButton')}
            categoryId={1}
            showWeightOption
            showLocationOption
            showSupplyOption
            showRandomOption
          />
        </Widget>
        <Widget title={t('components.localSources.title')}>
          <source-mix
            ref={localSourceMixRef}
            usage="DELIVERY"
            categoryIds="[2]"
            deviceId={selectedDeviceId}
          />
          <choose-for-me
            deviceId={selectedDeviceId}
            title={t('components.localSources.chooseForMeButton')}
            categoryId={2}
            showWeightOption
            showLocationOption
            showSupplyOption
            showRandomOption
          />
        </Widget>
        <Widget title={t('components.generatedTogether.title')}>
          <generated-together
            id=""
            energyType="electricity"
            startDate="2019-01-01"
            endDate="2019-12-31"
            direction="usage"
          />
        </Widget>
      </Grid>
      <source-overview
        ref={sourceOverviewRef}
        rowSize={3}
        rowsPerRequest={3}
        deviceId={selectedDeviceId}
      />
      <source-map
        apiKey={googleMapsApiKey}
        deviceId={selectedDeviceId}
        style={{ width: '100%', height: '900px' }}
      />
    </Grid>
  );
};

Sources.propTypes = {
  selectedDeviceId: PropTypes.string.isRequired,
  googleMapsApiKey: PropTypes.string.isRequired,
};

export default connect((state) => ({
  selectedDeviceId: state.app.selectedDeviceId,
  googleMapsApiKey: state.app.googleMapsApiKey,
}))(Sources);
