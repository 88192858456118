import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Redux */
import { toggleProps } from '../../redux/actions/app';

/* Components */
import ProfileMenu from '../profile-menu/ProfileMenu';

const Header = ({ dispatch, open, fake }) => {
  const toggleMenu = () => {
    dispatch(toggleProps('openMainMenu'));
  };

  const profileMenuTop = 57;
  const profileMenuLeft = 0;

  return (
    <StyledComponent className={fake && 'fake'}>
      <div className="menu-toggle" onClick={toggleMenu}>
        <span className={`hamburger ${open && 'open'}`} />
      </div>
      <div className="content">
        <div className="content--left">
          <Link to="/" className="logo">
            <img src={window.powerpeers.ASSETS_LOGO} alt="logo" />
          </Link>
        </div>
        <div className="content--right">
          <ProfileMenu
            top={profileMenuTop}
            left={profileMenuLeft}
          />
        </div>
      </div>
    </StyledComponent>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  fake: PropTypes.bool,
};

Header.defaultProps = {
  fake: false,
};

export default connect((state) => ({
  open: state.app.openMainMenu,
}))(Header);
