import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* Style */
import StyledComponent from './style';

/* Redux */
import { switchSelectedDevice } from '../../redux/actions/app';

/* Objects */
import Avatar from '../../objects/avatar';
import Radio from '../../objects/radio';

const ProfileMenu = ({
  dispatch, selectedDeviceId, userName, devices, avatar,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleAccountChange = (name, value) => {
    dispatch(switchSelectedDevice(value));
    setMenuOpen(false);
  };

  return (
    <StyledComponent onClick={toggleMenu}>
      <div className="avatar">
        <Avatar image={avatar} />
      </div>
      <div className="current">
        <span className="name"><b>{userName}</b></span>
        {/* <span className="address">{selectedDeviceId}</span> */}
      </div>
      <i className={`arrow ${menuOpen && 'open'}`} />
      <div className={`dropdown ${menuOpen && 'open'}`}>
        {devices && devices.map((device) => {
          if (!device.id) return null;

          return (
            <Radio
              key={device.id}
              name="selectedAccount"
              holderClassName="link account"
              value={device.id}
              checked={selectedDeviceId === device.id}
              onChange={handleAccountChange}
            >
              <div className="description">
                <span className="type">{t(`deviceTypes.${device.deviceType.toLowerCase()}`)}</span>
                <span className="id">{device.id}</span>
              </div>
            </Radio>
          );
        })}
        <Link to="/logout" className="link">
          {t('logoutButton')}
        </Link>
      </div>
    </StyledComponent>
  );
};

ProfileMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedDeviceId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ProfileMenu.defaultProps = {
  avatar: null,
};

export default connect((state) => ({
  selectedDeviceId: state.app.selectedDeviceId,
  userName: state.user.userName,
  avatar: state.user.avatar,
  devices: state.devices.all,
}))(ProfileMenu);
