export default (deviceId, devices) => {
  const selectedDevice = devices.find((device) => device.id === deviceId);
  const { estimatedSupply, estimatedUsage } = selectedDevice;
  const hasEstimatedSupply = (estimatedSupply && estimatedSupply > 0) === true;
  const hasEstimatedUsage = (estimatedUsage && estimatedUsage > 0) === true;

  return {
    hasEstimatedSupply,
    hasEstimatedUsage,
  };
};
